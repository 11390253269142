import axios from 'axios';

export const errorMessagesFromErrorResponse = (error: unknown): string[] | null => {
  if (!axios.isAxiosError(error)) return null;

  return error.response?.data
    ? 'error' in error.response.data
      ? [error.response.data.error]
      : 'errors' in error.response.data
        ? error.response.data.errors
        : null
    : null;
};
