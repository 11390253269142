import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import { css, Global } from '@emotion/react';
import { colors } from '~/styles/colors';
import { zIndex } from '~/styles/zIndex';

type IUseProgressBar = () => {
  renderStyles: () => JSX.Element;
};

export const useProgressBar: IUseProgressBar = () => {
  const COLOR = colors.progressBar;

  useEffect(() => {
    const routeChangeStart = () => {
      NProgress.start();
    };

    const routeChangeEnd = () => {
      NProgress.done();
    };

    NProgress.configure({ minimum: 0.6, showSpinner: false });
    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeEnd);
    Router.events.on('routeChangeError', routeChangeEnd);
  }, []);

  const renderProgressStyles = () => (
    <Global
      styles={css`
        #nprogress {
          pointer-events: none;
        }

        #nprogress .bar {
          background: ${COLOR};
          position: fixed;
          z-index: ${zIndex.progressBar};
          top: 0;
          left: 0;
          width: 100%;
          height: 3px;
        }

        #nprogress .peg {
          display: block;
          position: absolute;
          right: 0;
          width: 100px;
          height: 100%;
          box-shadow:
            0 0 10px ${COLOR},
            0 0 5px ${COLOR};
          opacity: 1;
          transform: rotate(3deg) translate(0, -4px);
        }

        .nprogress-custom-parent {
          overflow: hidden;
          position: relative;
        }

        .nprogress-custom-parent #nprogress .spinner,
        .nprogress-custom-parent #nprogress .bar {
          position: absolute;
        }
        @keyframes nprogress-spinner {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
        @keyframes nprogress-spinner {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      `}
    />
  );

  return {
    renderStyles: renderProgressStyles,
  };
};
