import type { EventHint, Event, Breadcrumb } from '@sentry/nextjs';

// NOTE: analytics.google.com への通信が失敗したエラーが頻出したので無視するための関数
// スタックトレースがない場合のエラーをフィルタするのにbreadcrumbsを利用している
// https://github.com/getsentry/sentry-javascript/issues/7275

const filterTargetDomains = [
  // NOTE: GAのドメインが複数ありそう
  // https://developers.google.com/tag-platform/security/guides/csp?hl=ja#google_analytics_4_google_analytics
  'analytics.google.com',
  'google-analytics.com',
  'googletagmanager.com',
];

// see also: https://gist.github.com/jeengbe/4bc86f05a41a1831e6abf2369579cc7a
export function shouldIgnoreError(event: Event, hint: EventHint): boolean {
  return (
    isRecaptchaBadTimeoutRejection(event, hint) ||
    isNetworkErrorFrom3rdParties(event) ||
    isErrorFromAnonymousFiles(event)
  );
}

// NOTE: traceのfilenameが<anonymous>の場合Google Tag Managerなどでinjectされたスクリプトと判断して無視する
// see also: https://github.com/getsentry/sentry-javascript/issues/3147#issuecomment-768978043
// TODO: SDK v8でthirdPartyErrorFilterIntegrationを利用できたら削除したいが2024/9/24時点でiOS v16系でエラーが通知されず未対応
function isErrorFromAnonymousFiles(event: Event): boolean {
  const traceFrames = event?.exception?.values?.[0].stacktrace?.frames;
  if (!traceFrames) return false;
  return traceFrames.some((frame) => frame.filename?.includes('<anonymous>'));
}

// https://github.com/getsentry/sentry-javascript/issues/2514
function isRecaptchaBadTimeoutRejection(_: Event, hint: EventHint): boolean {
  return hint.originalException === 'Timeout';
}

const typeErrorFetchFailedValues = new Set([
  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  'Load failed',
]);

function isTimestampMoreThan5sOld(timestamp?: number): boolean {
  const now = Date.now();
  return typeof timestamp !== 'undefined' && now - timestamp * 1000 > 5000;
}

function isErroneousBreadcrumb(breadcrumb: Breadcrumb): boolean {
  if (
    breadcrumb.level !== 'error' ||
    (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')
  ) {
    return false;
  }

  const url = breadcrumb.data?.url as string | undefined;
  if (!url) return false;

  // NOTE: 以下の部分だけ、フィルタしたいURLを配列指定したかったので変更した
  return filterTargetDomains.some((domain) => url.includes(domain));
  // return url === 'urlThatIsOftenBlocked' || url.startsWith('startOfUrlThatIsOftenBlocked');
}

function isIncludedErroneousBreadcrumb(breadcrumbs: (Breadcrumb | undefined)[]): boolean {
  // We go from the back since the last breadcrumb is most likely the erroneous one
  for (let i = breadcrumbs.length - 1; i >= 0; i--) {
    const breadcrumb = breadcrumbs[i];
    if (!breadcrumb) continue;

    // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
    if (isTimestampMoreThan5sOld(breadcrumb.timestamp)) {
      break;
    }

    if (isErroneousBreadcrumb(breadcrumb)) {
      return true;
    }
  }
  return false;
}

function isNetworkErrorFrom3rdParties(event: Event): boolean {
  const exception = event.exception?.values?.[0];

  if (
    exception?.type !== 'TypeError' ||
    !typeErrorFetchFailedValues.has(exception.value as string) ||
    !event.breadcrumbs
  ) {
    return false;
  }

  return isIncludedErroneousBreadcrumb(event.breadcrumbs);
}
