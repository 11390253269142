import { z } from 'zod';

export type EnvironmentVariable = z.infer<typeof envSchema>;

const envSchema = z.object({
  environment: z.enum(['production', 'staging', 'development', 'test', 'debug']),
  maintenanceMessage: z.string().optional(),
  maintenanceStartDateTime: z.string().datetime({ offset: true }).optional(),
  maintenanceEndDateTime: z.string().datetime({ offset: true }).optional(),
  apiBaseUrl: z.string().min(1),
  loginUrl: z.string().min(1),
  kenallPublicKey: z.string().min(1),
  salesforce: z.object({
    trackingHost: z.string().min(1),
    pid: z.string().min(1),
  }),
  dataDogRUM: z.object({
    applicationId: z.string().min(1),
    clientToken: z.string().min(1),
    service: z.string().min(1),
  }),
});

const validateEnv = envSchema.parse;

const env = validateEnv({
  environment: process.env.NEXT_PUBLIC_ENV,
  maintenanceMessage: process.env.NEXT_PUBLIC_MAINTENANCE_MESSAGE,
  maintenanceStartDateTime: process.env.NEXT_PUBLIC_MAINTENANCE_START_DATE_TIME,
  maintenanceEndDateTime: process.env.NEXT_PUBLIC_MAINTENANCE_END_DATE_TIME,
  apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
  loginUrl: process.env.NEXT_PUBLIC_LOGIN_URL,
  kenallPublicKey: process.env.NEXT_PUBLIC_KENALL_PUBLIC_KEY,
  salesforce: {
    trackingHost: process.env.NEXT_PUBLIC_SALESFORCE_TRACKING_HOST,
    pid: process.env.NEXT_PUBLIC_SALESFORCE_PID,
  },
  dataDogRUM: {
    // NOTE: 環境変数で切り替えることはないのでベタ書き
    applicationId: 'a9da1eba-b0ef-4ad6-bbc2-6c2d15b30895',
    clientToken: 'pub3030751464e6eed306582ac5dfc60f27',
    service: 'timee-register-web',
  },
});

const getApiBaseUrl = (): string | null => {
  if (typeof window === 'undefined') {
    // サーバーサイドでの処理の場合
    return null;
  }

  const demoApiBaseUrlTemplate = process.env.NEXT_PUBLIC_DEMO_API_BASE_URL_TEMPLATE;
  if (!demoApiBaseUrlTemplate) {
    // 環境変数にdemo環境用テンプレートが存在しない場合
    return null;
  }

  const host = window.location.host;
  const parts = host.split('.');

  if (parts.length !== 4) {
    // 想定しているドメイン(app-new.demoX.stg-taimee.com)と異なる場合
    return null;
  }

  const demoLabel = parts[1].toLowerCase();

  // .env.stagingで定義されているDEMO_API_BASE_URL_TEMPLATEを使用してDemo環境のAPIのベースURLを生成する
  const demoApiBaseUrl = demoApiBaseUrlTemplate.replace('<DEMO_ENV>', demoLabel);

  return demoApiBaseUrl;
};

if (process.env.NEXT_PUBLIC_ENV === 'staging') {
  // ENV=stagingにはStaging環境とDemo環境が含まれる
  // demo環境用のAPIを取得できた場合は上書きする
  env.apiBaseUrl = getApiBaseUrl() ?? env.apiBaseUrl;
}

export const publicEnv = env;

export const validateEnvironmentVariable = () => {};
